var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "mypage-drawer",
    attrs: {
      "width": "100%",
      "max-width": "240"
    }
  }, [_c('v-row', {
    staticClass: "py-6 mt-3",
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-end"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "orange",
      "size": "32px"
    }
  }, [_vm._v(_vm._s(_vm.mdiPencilBox))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("마이페이지")])]), _c('v-spacer')], 1), _c('v-divider', {
    staticClass: "mb-3"
  }), _c('v-btn', {
    attrs: {
      "width": "100%",
      "text": "",
      "to": "/member/profile"
    }
  }, [_vm._v("회원정보수정")]), _c('v-btn', {
    attrs: {
      "width": "100%",
      "text": "",
      "to": "/member/points"
    }
  }, [_vm._v("포인트내역")]), _c('v-btn', {
    attrs: {
      "width": "100%",
      "text": "",
      "to": "/member/payments"
    }
  }, [_vm._v("입금내역확인")]), _c('v-btn', {
    attrs: {
      "width": "100%",
      "text": "",
      "to": "/member/gshop/orders"
    }
  }, [_vm._v("상품권 주문내역")]), _c('v-btn', {
    attrs: {
      "width": "100%",
      "text": "",
      "to": "/member/shop/purchases"
    }
  }, [_vm._v("상품 구매내역")]), _c('v-btn', {
    attrs: {
      "width": "100%",
      "text": "",
      "to": "/center/questions"
    }
  }, [_vm._v("1:1문의")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }