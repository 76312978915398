<template>
    <v-responsive width="100%" max-width="240" class="mypage-drawer">
        <v-row no-gutters class="py-6 mt-3" justify="center">
            <v-col class="text-end">
                <v-icon color="orange" size="32px" class="mr-2">{{ mdiPencilBox }}</v-icon>
            </v-col>
            <v-col cols="auto">
                <span class="title">마이페이지</span>
            </v-col>
            <v-spacer/>
        </v-row>
        <v-divider class="mb-3"/>
        <v-btn width="100%" text to="/member/profile">회원정보수정</v-btn>
        <v-btn width="100%" text to="/member/points">포인트내역</v-btn>
        <v-btn width="100%" text to="/member/payments">입금내역확인</v-btn>
        <v-btn width="100%" text to="/member/gshop/orders">상품권 주문내역</v-btn>
        <v-btn width="100%" text to="/member/shop/purchases">상품 구매내역</v-btn>
        <v-btn width="100%" text to="/center/questions">1:1문의</v-btn>
    </v-responsive>
</template>
<script>
import { mdiPencilBox } from "@mdi/js";
export default {
    data(){
        return {
            mdiPencilBox
        };
    }
}
</script>
<style scoped>
.mypage-drawer .title {
    font-size: 22px !important;
    font-weight: 700;
    text-align: center;
}
.mypage-drawer .v-btn--active:before {
    background-color: dodgerblue !important;
}


</style>