<template>
    <component :is="template">
        <v-responsive max-width="800" class="member-profile mx-auto py-15 py-xl-9 px-lg-6 px-md-3">
            <v-layout justify-center>
                <span class="headline mb-8">회원정보수정</span>
            </v-layout>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-text-field v-model="me.username" v-bind="$attrs" label="아이디" persistent-placeholder hide-details class="mx-xl-2 mt-4" disabled/>
                </v-col>
                <v-col cols="12" md="6">
                    <v-password-field v-model="me.password" v-bind="$attrs" label="비밀번호" persistent-placeholder hide-details class="mx-xl-2 mt-4" />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-text-field v-model="me.name" v-bind="$attrs" label="이름" persistent-placeholder hide-details class="mx-xl-2 mt-4" disabled />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field v-show="false" v-model="me.point" v-bind="$attrs" label="포인트" persistent-placeholder hide-details class="mx-xl-2 mt-4" disabled />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-col>
                            <v-select v-model="me.phone1" v-bind="$attrs" label="연락처" :items="appendPhones" persistent-placeholder hide-details disabled class="mx-xl-2 mt-4" />
                        </v-col>
                        <v-icon small class="mt-4">mdi-minus</v-icon>
                        <v-col>
                            <v-text-field v-model="me.phone2" v-bind="$attrs" hide-details disabled maxlength="4" class="mx-xl-2 mt-4"/>
                        </v-col>
                        <v-icon small class="mt-4">mdi-minus</v-icon>
                        <v-col>
                            <v-text-field v-model="me.phone3" v-bind="$attrs" hide-details disabled maxlength="4" class="mx-xl-2 mt-4"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-btn v-if="me.recertEnabled" outlined x-large width="100%" color="primary" class="mt-4" @click="$refs.kcpCert.open()"><v-icon class="mr-2 mt-1">mdi-certificate-outline</v-icon>본인인증</v-btn>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-col>
                            <v-text-field v-model="me.email1" v-bind="$attrs" label="이메일" persistent-placeholder hide-details class="mx-xl-2 mt-4" />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="me.email2" v-bind="$attrs" hide-details class="mx-xl-2 mt-4">
                                <template #prepend-inner>
                                    <v-icon size="20" class="mt-1">mdi-at</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-icon small class="mt-4" color="transparent">mdi-at</v-icon>
                        <v-col cols="12" md="auto">
                            <v-select v-model="me.email3" v-bind="$attrs" :items="prependEmails" item-text="text" item-value="value" hide-details class="mx-xl-2 mt-md-4" @input="me.email2 = me.email3"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-col cols="12" md="6">
                            <v-text-field v-model="me.postcode" v-bind="$attrs" label="우편번호" persistent-placeholder hide-details readonly class="mx-xl-2 mt-4" @click="$refs.daumPostcode.open()" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-col cols="12" md="6">
                            <v-text-field v-model="me.address1" v-bind="$attrs" label="기본주소" persistent-placeholder hide-details readonly class="mx-xl-2 mt-4" @click="$refs.daumPostcode.open()" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="me.address2" v-bind="$attrs" label="상세주소" persistent-placeholder hide-details class="mx-xl-2 mt-4" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
            <v-divider class="mx-xl-2 mt-12"/>
            <v-row no-gutters class="mx-xl-2 mt-4">
                <v-btn large outlined @click="withdraw">회원탈퇴</v-btn>
                <v-spacer/>
                <v-btn large color="primary" @click="save">저장</v-btn>
            </v-row>
        </v-responsive>

        <kcp-cert ref="kcpCert" @input="certify"></kcp-cert>
        <daum-postcode ref="daumPostcode" @input="({ postcode, address }) => { me.postcode = postcode; me.address1 = address; }" />
    </component>
</template>
<script>
import { mapActions, mapState } from "vuex";
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import MypageDrawer from "@/components/client/mypage/mypage-drawer.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

export default {
    components: {
        MypageDrawer,
        VPasswordField,
        KcpCert,
        DaumPostcode
    },
    data(){
        return {
            me: {},

            appendPhones: ["010", "011", "016", "017", "019"],
            prependEmails: [
                { text: ":: 직접입력 ::", value: null },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ]
        }
    },
    mounted(){
        this.getSiteInfo();
        if(this.logoff) this.$router.push(`/login`);
        this.init();
    },
    methods: {
        ...mapActions("siteInfo", ["getSiteInfo"]),
        async init(){
            let { me } = await api.v1.me.profile.get();
            let [ phone1, phone2, phone3 ] = me.phone?.split?.("-") || [];
            let [ email1, email2 ] = me.email?.split?.("@") || {};

            this.me = {
                ...me,
                phone1, phone2, phone3,
                email1, email2, email3: null
            }
        },
        validate(){
            try{
                if(this.me?.password?.match(/ /)) throw new Error("비밀번호에 공백은 제외해서 입력해주세요");
                if(this.me.password && (0 <= this.me.password.indexOf(this.me.username) || !this.me.password.match(/[0-9]/) || !this.me.password.match(/[a-zA-Z]/) || !this.me.password.match(/[`~!@#$%^&*()\-_=+\[\]{};:'",.<>\/\?]/) || this.me.password.length < 8 || 20 < this.me.password.length )) throw new Error("비밀번호는 회원 아이디와 동일하지 않아야 하며 영어+숫자+특수문자 혼합 문자로 최소 8~20자리입니다");
                if(this.me.email1.match(/[@'"`(){}\[\]]/) || this.me.email1.substr(-1) == '.') throw new Error("이메일 양식을 확인해주세요")
                if(!this.me.email2.match(/[.]/) || this.me.email2.match(/[^0-9a-zA-Z.]/)) throw new Error("이메일 양식을 확인해주세요");
                if(!this.me.postcode) throw new Error("우편번호를 입력해주세요");
                if(!this.me.address1) throw new Error("기본주소를 입력해주세요");
                
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async save(){
            let me = this.me;
            if(this.validate()){
                await api.v1.me.profile.put({
                    ...me,
                    password: me.password ? CryptoAES.encrypt(me.password) : null,
                    phone: [me.phone1, me.phone2, me.phone3].join("-"),
                    email: [me.email1, me.email2].join("@")
                });
    
                alert("저장되었습니다");
                this.$router.go(0);
            }
        },
        certify({ name, phone, birthday, ci }){
            this.me.name = name;
            
            let [phone1, phone2, phone3] = phone?.phoneNumberFormat?.()?.split?.('-') || [];
            this.me.phone1 = phone1;
            this.me.phone2 = phone2;
            this.me.phone3 = phone3;

            this.me.birthday = birthday;

            this.me.ci = ci;
        },
        async withdraw(){
            if(confirm("회원탈퇴 처리후에는 회원님의 개인정보를 복원할수 수 없습니다. 그래도 회원탈퇴를 진행하시겠습니까?")){
                await api.v1.me.withdraw();
                await this.$store.dispatch("logout");

                alert("이용해주셔서 감사합니다");

                this.$router.push(`/`);
            }
        }
    },
    computed: {
        ...mapState(["siteInfo"]),
        template(){
            return () => import(`@/templates/${this.$theme.pages.mypage}`);
        },
        logoff(){
            return !this.$store.state.accessToken;
        }
    }
}
</script>

<style scoped>
.member-profile .title {
    font-size: 22px !important;
    font-weight: 600;
}
</style>